import CountryAccess from "components/AccessValidation/CountryAccess";
import InvestorStoreAccess from "components/AccessValidation/InvestorStoreAccess";
import { FC, memo } from "react";

interface ProtectedPageProps {
  children: React.ReactElement;
  pageProps: any;
}

const ProtectedPage: FC<ProtectedPageProps> = ({ children, pageProps }) => {
  return (
    <CountryAccess pageProps={pageProps}>
      <InvestorStoreAccess>
        {children}
      </InvestorStoreAccess>
    </CountryAccess>
  );
};

export default memo(ProtectedPage);
